.TopLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  font-size: 1.5rem;
  font-weight: bolder;
  padding-top: 2rem;
}
.TopLinkContainer {
  text-decoration: none;
  color: white;
}