.Home {
  height: 100vh;
  /* background: rgb(0, 0, 0); */
  
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url('../assets/background.png');
  background-size: cover; /* Or contain, auto, etc. */
  background-repeat: no-repeat; /* Or repeat, repeat-x, repeat-y */
  background-position: center center;
  font-family: 'CoinbaseSansMedium';
}

.HomeContentContainer {
  padding-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  color: white;
}
/* .HomeContentImageContainer, .HomeContentDescription {
  border: 0.3rem solid black;
  width: 32rem;
  height: 32rem;
  border-radius: 1rem;
} */

.HomeContentImage {
  height: 42rem;
  border-radius: 1rem;
}

.HomeContentImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeContentDescription {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.HomeContentDescription {
  display: flex;
  flex-direction: column;
  gap:2rem;
}

.HomeContentText {
  margin-top: 8rem;
  padding-left: 2rem;
}

.HomeContentTextTitle {
  font-size: 2.2rem;
  font-weight: bolder;
}

.HomeContentTextText {
  font-size: 1.5rem;
  font-family: CoinbaseSansRegular;
}

.HomeContentBuyButton {
  background-color: rgb(24, 24, 24);
  padding: 1rem;
  width: 10rem;
  text-align: center;
  margin-left: 2rem;
}

.HomeContentBuyButtonLink {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
}

@media (max-width: 800px) {
}
